// handler function
import ERROR_MESSAGE from 'constants/errorMessage';
import handle from './handler';

// constact

const {
  handleDefault,
  handleMaintenance,
  handleNoPermission,
  handleInvalidParameter,
  handleInvalidPIN,
  handleSystemError,
  handleNotFound,
  handleNotSubscription,
  handleUnAuthorized,
  handleLogoutTradingUser,
  handleInternalServerError,
} = handle;

/**
 * APIErrorMessage
 * object for grouping function error handle
 * add this object with your new function handler
 */
const APIErrorMessage = {
  [ERROR_MESSAGE.DEFAULT]: handleDefault,
  [ERROR_MESSAGE.MAINTENANCE]: handleMaintenance,
  [ERROR_MESSAGE.NOPERMISSION]: handleNoPermission,

  [ERROR_MESSAGE.INVALIDPARAMETER]: handleInvalidParameter,
  [ERROR_MESSAGE.INVALID_PARAMETER]: handleInvalidParameter,
  [ERROR_MESSAGE.INVALID_LOGIN]: handleInvalidParameter,
  [ERROR_MESSAGE.ERR_SYSTEM]: handleInvalidParameter,
  [ERROR_MESSAGE.ERR_INVALID_PARAMS]: handleInvalidParameter,
  [ERROR_MESSAGE.ERR_ORDER_FAIL]: handleInvalidParameter,
  [ERROR_MESSAGE.ERR_PIN_INVALID]: handleInvalidParameter,
  [ERROR_MESSAGE.ERR_LOGIN_FAIL]: handleInvalidParameter,
  [ERROR_MESSAGE.INVALID_AMEND]: handleInvalidParameter,

  [ERROR_MESSAGE.INVALID_PIN]: handleInvalidPIN,
  [ERROR_MESSAGE.SYSTEMERROR]: handleSystemError,
  [ERROR_MESSAGE.NOTFOUND]: handleNotFound,
  [ERROR_MESSAGE.NOSUBSCRIPTION]: handleNotSubscription,
  [ERROR_MESSAGE.UNAUTHORIZED]: handleUnAuthorized,
  [ERROR_MESSAGE.INVALID_SESSION]: handleLogoutTradingUser,
  [ERROR_MESSAGE.ERR_LOGIN_AUTHORIZATION]: handleLogoutTradingUser,
  [ERROR_MESSAGE.ERR_PIN_AUTHORIZATION]: handleLogoutTradingUser,
  [ERROR_MESSAGE.ERR_UNKNOWN_BROKER]: handleLogoutTradingUser,
};

/**
 * APIErrorMessageHandler
 * call object APIErrorMessage based on error type
 * @param {string} errorType | required
 * @param {string} message | required
 * @param {string|object error} singleError | optional
 */
const APIErrorMessageHandler = (errorType, message, singleError) =>
  (APIErrorMessage[errorType] || APIErrorMessage[ERROR_MESSAGE.DEFAULT])(
    errorType,
    message,
    singleError,
  );

/**
 * Exodus API error message handler
 * @param {number} status - HTTP Response status | required
 * @param {string} errorType - Error types | required
 * @param {string} message - Error message | required
 */
const APIExodusErrorHandler = (status, errorType, message) => {
  const listErrorResponse = {
    DEFAULT: handleDefault,
    400: handleInvalidParameter,
    401: handleUnAuthorized,
    402: handleNotSubscription,
    403: handleDefault,
    404: handleNotFound,
    429: handleDefault,
    500: handleInternalServerError,
    502: handleDefault,
    503: handleMaintenance,
    504: handleDefault,
  };

  const response = listErrorResponse[status] || listErrorResponse.DEFAULT;

  return response(errorType, message);
};
export default { APIErrorMessageHandler, APIExodusErrorHandler };
