/* eslint-disable no-param-reassign */
import axios from 'axios';

//  Utils
import auth from 'utils/auth';

// constact
import { API_EXODUS_URL } from 'constants/api';
import { dispatchSetErrorCode } from './utils';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

/**
 * generateRefreshToken
 * hit API /login/refresh
 * destory old cookies
 * save response data to cookies
 * @param {string} refreshToken
 */
function generateRefreshToken(refreshToken, originalRequest) {
  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject });
    })
      .then((token) => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return axios(originalRequest);
      })
      .catch((err) => Promise.reject(err));
  }
  isRefreshing = true;
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_EXODUS_URL}/login/refresh`, null, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      })
      .then((response) => {
        if (!response || !response.data || response.data.error) {
          throw new Error('Could not refresh token');
        }

        const {
          data: { data },
        } = response;

        const accessUser = auth.getUserAccess();

        // remove saved cookies
        auth.destroyToken();

        const parsedAccessExpired =
          new Date(data.access.expired_at).getTime() / 1000;
        const parsedRefreshExpired =
          new Date(data.refresh.expired_at).getTime() / 1000;

        // save userdata to credentials entities
        auth.saveToken({
          accessToken: data.access.token,
          accessTokenExp: parsedAccessExpired,
          refreshToken: data.refresh.token,
          refreshTokenExp: parsedRefreshExpired,
          accessUser,
        });

        dispatchSetErrorCode(null);
        // change token in header
        originalRequest.headers.Authorization = `Bearer ${data.access.token}`;
        processQueue(null, data.access.token);

        resolve(axios(originalRequest));
      })
      .catch((err) => {
        // remove saved cookies
        if (window) {
          const ERROR_CODE = 401;
          dispatchSetErrorCode(ERROR_CODE);
          // auth.destroyToken();
          // router.push('/logout');
        }
        processQueue(err, null);
        reject(err);
      })
      .then(() => {
        isRefreshing = false;
      });
  });
}

export default generateRefreshToken;
