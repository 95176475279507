/* eslint-disable no-undef */
import axios from 'axios';
// constact
import { API_EXODUS_URL, CUSTOM_USER_AGENT } from 'constants/api';
import { isClient } from 'constants/app';
import ERROR_MESSAGE from 'constants/errorMessage';
// handler
import handleErrorMessageAPI from 'global/AlertErrorMessage';
import auth from 'utils/auth';

import { sentryLog } from 'utils/logger';
import handler from './APIErrorMessage';
import handle from './APIErrorMessage/handler';

const { APIExodusErrorHandler } = handler;

function requestExodus(
  isShowAlertMessage = true,
  context = null,
  customUserAgent = false,
  extraHeaders = {},
) {
  let headers: Record<string, any> = {
    Accept: 'application/json',
    ...extraHeaders,
  };

  if (customUserAgent) {
    headers = { ...headers, 'User-Agent': CUSTOM_USER_AGENT };
  }
  // create new api instance
  const apiInstance = axios.create({
    baseURL: API_EXODUS_URL,
    headers,
  });

  // intercept api request
  apiInstance.interceptors.request.use(
    // onFullfilled
    (req) => {
      const parsedCookie = auth.getToken();
      const {
        accessToken: { token },
      } = parsedCookie;
      const isOnline = isClient() ? window?.navigator?.onLine : true;

      if (!isOnline) {
        handleErrorMessageAPI(ERROR_MESSAGE.OFFLINE, ERROR_MESSAGE.ALERT_RED);
        return Promise.reject(ERROR_MESSAGE.OFFLINE);
      }

      // refresh token
      if (req.headers.Authorization) {
        req.headers = {
          ...req.headers,
          Authorization: req.headers.Authorization,
        };
      } else if (req.url !== '/user/setting/password/reset' && token) {
        // request with header accessToken
        // add access token to Authorization
        req.headers = {
          ...req.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      return req;
    },

    // onRejected
    (error) => Promise.reject(error),
  );

  // Intercept api response
  apiInstance.interceptors.response.use(
    // on fullfilled
    async (response) => {
      const {
        data: {
          error_type: errorType,
          message = ERROR_MESSAGE.DEFAULT_ERROR_MESSAGE,
        },
        status,
      } = response;

      if ([401].includes(status)) {
        return handle.handleUnAuthorized(response.config);
      }
      // only there's an error and isShowAlertMessage = true
      if (errorType && isShowAlertMessage) {
        APIExodusErrorHandler(status, errorType, message);
      }

      return response;
    },

    // on rejected
    (error) => {
      if (error.response) {
        const { status, data } = error.response;

        // eslint-disable-next-line prefer-destructuring
        let message = data.message;
        let errorType = data.error_type;

        if ([401].includes(status)) {
          const ret = handle.handleUnAuthorized(error.config);
          return ret;
        }

        if (isShowAlertMessage) {
          // the real error message is unparsed in `message`
          if (!errorType) {
            const parsedMessage = JSON.parse(data.message);
            message = parsedMessage.message;
            errorType = parsedMessage.error_type;
          }

          APIExodusErrorHandler(status, errorType, message);
          sentryLog({
            scope: 'server-layer',
            level: 'error',
            httpStatusCode: status,
            tags: [
              { title: 'api-error-url', value: error?.request?.responseURL },
              { title: 'api-error-text', value: error?.request?.responseText },
            ],
            error,
          });
        }
      }

      return Promise.reject(error);
    },
  );

  return apiInstance;
}

export default requestExodus;
