/* eslint-disable no-else-return */
//  Utils
import auth from 'utils/auth';

// alert error message
import handleErrorMessageAPI from 'global/AlertErrorMessage';

// refresh token

// constant
import ERROR_MESSAGE from 'constants/errorMessage';
import { isClient } from 'constants/app';

import generateRefreshToken from './generateRefreshToken';
import { dispatchSetErrorCode } from './utils';

/**
 * handleDefault
 * @param {*} errorType | optional
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
const handleDefault = (errorType, message) => {
  if (errorType === ERROR_MESSAGE.PAYWALL_NOSUBSCRIPTION) return;
  if (errorType === ERROR_MESSAGE.FORBIDDEN) return;

  const errMessageDefault = message;
  handleErrorMessageAPI(errMessageDefault, ERROR_MESSAGE.ALERT_RED);
};

/**
 * handleMaintenance
 * web legacy: app\lib\Api.js line 184
 * @param {*} errorType | MAINTENANCE | ERROR_GATEWAY
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
const handleMaintenance = (errorType, message) => {
  if (errorType === 'MAINTENANCE' && message) {
    dispatchSetErrorCode(503, message);
  }
};

/**
 * handleNoPermission
 * web legacy: app\lib\Api.js line 187
 * @param {*} errorType | optional
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
const handleNoPermission = () => {
  handleErrorMessageAPI(
    ERROR_MESSAGE.PERMISSION_DENIED,
    ERROR_MESSAGE.ALERT_RED,
  );
};

/**
 * handleUnAuthorized
 * web legacy: app\lib\Api.js line 192
 * @param {*} errorType | optional
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
const handleInvalidParameter = (_, message) => {
  handleErrorMessageAPI(message, ERROR_MESSAGE.ALERT_RED);
};

/**
 * handleInvalidPIN
 * web legacy: app\lib\Api.js line 229
 * @param {*} errorType | optional
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
const handleInvalidPIN = () => {
  // TODO: handleInvalidPIN
};

/**
 * handleSystemError
 * @param {*} errorType | optional
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
const handleSystemError = (_, message) => {
  handleErrorMessageAPI(message, ERROR_MESSAGE.ALERT_RED);
};

/**
 * handleInternalServerError
 * @param {*} errorType | optional
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
// eslint-disable-next-line no-unused-vars
const handleInternalServerError = (_, message) => {
  dispatchSetErrorCode(500);
};

/**
 * handleNotFound
 * @param {*} errorType | optional
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
const handleNotFound = (_, message) => {
  handleErrorMessageAPI(message, ERROR_MESSAGE.ALERT_RED);
};

/**
 * handleNotSubscription
 * web legacy: app\lib\Api.js line 238
 * @param {*} errorType | optional
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
const handleNotSubscription = () => {
  // TODO: url
};

/**
 * handleUnAuthorized
 * web legacy: app\lib\Api.js line 255
 * @param {*} errorType | optional
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
// eslint-disable-next-line consistent-return
const handleUnAuthorized = (originalRequest) => {
  const parsedCookie = auth.getToken();
  const refreshToken = parsedCookie?.refresh?.token;
  const ERROR_CODE = 401;

  // hit API refresh token
  // set new token to cookies
  if (refreshToken) {
    return generateRefreshToken(refreshToken, originalRequest);
  } else if (isClient()) {
    dispatchSetErrorCode(ERROR_CODE);
    // router.push('/logout');
  }
  // next step:
  // TODO: logout user, getLocalRefresh,
  // TODO: destroy cuurent crips session, clear
};

/**
 * handleLogoutTradingUser
 * web legacy: app\lib\Api.js line 271
 * INVALID_SESSION, ERR_LOGIN_AUTHORIZATION,
 * ERR_PIN_AUTHORIZATION, ERR_UNKNOWN_BROKER
 * @param {*} errorType | optional
 * @param {*} message | optional
 * @param {*} singleError  | optional
 */
const handleLogoutTradingUser = () => {
  // TODO: call function logout trading user
};

export default {
  handleDefault,
  handleMaintenance,
  handleNoPermission,
  handleInvalidParameter,
  handleInvalidPIN,
  handleSystemError,
  handleNotFound,
  handleNotSubscription,
  handleUnAuthorized,
  handleLogoutTradingUser,
  handleInternalServerError,
};
